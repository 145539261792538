import React from 'react';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import './FeatureFlagStats.scss';
import { FeatureFlag, FeatureFlagDataType, FeatureFlagExperiment } from './types';
import { DynamicTable } from '../DynamicTable/DynamicTable';
import { useNavigate } from 'react-router-dom';

interface FeatureFlagOverridesProps { 
  featureFlag: FeatureFlag;
}
export function FeatureFlagOverrides(props: FeatureFlagOverridesProps) {
  const { featureFlag }  = props;
  const navigate = useNavigate();
  const [featureFlagOverrides, _featureFlagOverrides, featureFlagOverridesLoaded] 
    = useApi(`/api/featureflags/${featureFlag.featureFlagId}/overrides/clients`);



 
  const onClientOverrideRowClick = (row) => { 
    navigate(`/account/${row.clientId}`);
  }

  
  const featureFlagType = props.featureFlag?.dataType;
  const featureFlagOverridesSanitized = (featureFlagOverrides as any[] || []).map(override => {
    const sanitizedValue = { ...override };
    delete sanitizedValue['dataType'];
    if(featureFlagType !== FeatureFlagDataType.BoolValue) {
      delete sanitizedValue.boolValue;
    }
    if(featureFlagType !== FeatureFlagDataType.NumberValue) {
      delete sanitizedValue.numberValue;
    }
    if(featureFlagType !== FeatureFlagDataType.DecimalValue) {
      delete sanitizedValue.decimalValue;
    }
    if(featureFlagType !== FeatureFlagDataType.JsonValue) {
      delete sanitizedValue.jsonValue;
    }
    if(featureFlagType !== FeatureFlagDataType.StringValue) {
      delete sanitizedValue.stringValue;
    }
    return sanitizedValue;
  });


  const loading = !featureFlagOverridesLoaded;
  
  return (
    <div className="feature-flag-overrides">
      <Loader isLoading={loading} />
      <div className="feature-overrides-clients">
        {featureFlagOverridesLoaded && <DynamicTable
            header='Clients With Overrides'
            subHeader='*View is limited to latest 5000 clients. Click on a row to view client details.'
            data={featureFlagOverridesSanitized}
            hyperLinkColumns={[2, 3]}
            onColumnClick={onClientOverrideRowClick} />
        }
      </div>
    </div>
  );
}