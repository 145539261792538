import './AzureDevOpsReleaseTable.scss';
import React, { useState } from 'react';
import { AzureDevOpsRelease, DeploymentStatus, Environments } from './types';
import { ReleaseEnvironmentPill } from './ReleaseEnvironmentPill';
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell, Button, Label, Icon, Statistic, StatisticLabel, StatisticValue } from 'semantic-ui-react';
import { ReleaseConnector } from './ReleaseConnector';
import { AzureDevOpsReleaseModal } from './AzureDevOpsReleaseModal';


export type AzureDevOpsReleaseTableProps = {
  releases: AzureDevOpsRelease[];
  refreshReleases: () => void;
  isDevToProd: boolean;
}

export function AzureDevOpsReleaseTable(props: AzureDevOpsReleaseTableProps) {
  const [deployModalState, setDeployModalState] = useState({
    open: false,
    fromEnvironment: null,
    toEnvironment: null,
    releases: [] as AzureDevOpsRelease[]
  });

  const getReleaseEnvironment = (release: AzureDevOpsRelease, environmentName: string) => 
    release.environmentReleases.find((environment) => environment.environmentName?.startsWith(environmentName));

  const showConnector = (release: AzureDevOpsRelease, environment: string, nextEnvironment: string) => {

    //check if the release is deploying something to the next environment
    if(isReleaseDeploying(release, environment, nextEnvironment)) {
      return true;
    }

    const firstVersion = getReleaseEnvironment(release, environment);

    if(firstVersion?.releaseVersion == null) {
      return false;
    }

    const secondVersion = getReleaseEnvironment(release, nextEnvironment);

    if(secondVersion?.releaseVersion == null) {
      return false;
    }
   
    if(!firstVersion?.releaseId || !secondVersion?.releaseId) {
      return false;
    }

    if(firstVersion?.status === DeploymentStatus.Failed || secondVersion?.status === DeploymentStatus.Failed) { 
      return false;
    }

    return firstVersion?.releaseVersion === secondVersion?.releaseVersion; 
  }

  const shouldDeployVersion = (release: AzureDevOpsRelease, environment: string, nextEnvironment: string) => {
    const firstVersion = getReleaseEnvironment(release, environment);
    if(firstVersion?.releaseVersion == null) {
      return false;
    }

    const deployToEnvironment =  firstVersion.environments.find((environment) => environment.name?.startsWith(nextEnvironment));

    //dont deploy if the first version failed
    if(firstVersion?.status ===  DeploymentStatus.Failed || !firstVersion?.releaseId) { 
      return false;
    }

    //dont deploy if the destination environment doesnt exist
    if(deployToEnvironment == null) { 
      return false;
    }

    //dont deploy if the destination environment is deploying
    if(deployToEnvironment?.status === DeploymentStatus.InProgress) {
      return false;
    }
    
    //deploy if the destination environment is already deployed
    if(deployToEnvironment?.status !== DeploymentStatus.Succeeded) { 
      return true;
    }

    const currentVersionInNextEnvironment = getReleaseEnvironment(release, nextEnvironment);

    //only deploy if the destination version is not the same as the source version
    return firstVersion?.releaseVersion !== currentVersionInNextEnvironment?.releaseVersion; 
  }

  const isReleaseDeploying =  (release: AzureDevOpsRelease, environment: string, nextEnvironment: string) => { 
    const firstVersion = getReleaseEnvironment(release, environment);

    //if the origin environment == null then its not deployin
    if(firstVersion == null) {
      return false;
    }

    //check the desitination environment on the release for the current environment
    const deployToEnvironment =  firstVersion.environments.find((environment) => environment.name?.startsWith(nextEnvironment));

    return deployToEnvironment?.status === DeploymentStatus.InProgress;
  }

  const betaReleases = props.releases.filter(release => shouldDeployVersion(release, Environments.Dev, Environments.Beta));
  const onDeployBetaClick = () => { 
    setDeployModalState({
      open: true,
      fromEnvironment: Environments.Dev,
      toEnvironment: Environments.Beta,
      releases: betaReleases
    });
  }

  const prodReleaseFromEnvironment = props.isDevToProd ? Environments.Dev :  Environments.Beta;
  const prodReleases =  props.releases.filter(release => shouldDeployVersion(release, prodReleaseFromEnvironment,  Environments.Prod));
  const onDeployProdClick = () => {
    setDeployModalState({
      open: true,
      fromEnvironment: prodReleaseFromEnvironment,
      toEnvironment: Environments.Prod,
      releases: prodReleases
    });
  }

  const onDeployModalReleaseStarted = () => { 
    setDeployModalState({
      open: false,
      fromEnvironment: null,
      toEnvironment: null,
      releases: [] as AzureDevOpsRelease[]
    });
    props.refreshReleases();
  }

  const onDeployModalCancelClick = () => { 
    setDeployModalState({
      open: false,
      fromEnvironment: null,
      toEnvironment: null,
      releases: [] as AzureDevOpsRelease[]
    });
  }

  const deploymentCount = props.releases.reduce((prevValue, release) => {
    const isDeployingFromDev = isReleaseDeploying(release, Environments.Dev, props.isDevToProd ? Environments.Prod : Environments.Beta);
    prevValue += isDeployingFromDev ? 1 : 0;

    if(!props.isDevToProd) {
      const isDeployingBeta = isReleaseDeploying(release, Environments.Beta, Environments.Prod);
      prevValue += isDeployingBeta ? 1 : 0;
    }

    return prevValue;
  }, 0);
     
       
  return (
    <div className="azure-devops-table">
      <div className='azure-devops-table-header'>
        {!props.isDevToProd &&
        <Button as='div' labelPosition='right' className='deploy-beta-button' onClick={onDeployBetaClick}>
          <Button>
            <Icon name='rocket' />
            Deploy Beta
          </Button>
          <Label as='a' basic pointing='left'>
            {betaReleases?.length ?? 0}
          </Label>
        </Button>}
        <Button as='div' labelPosition='right' className='deploy-production-button' onClick={onDeployProdClick}>
          <Button basic>
            <Icon name='rocket' />
            Deploy Production
          </Button>
          <Label as='a' basic pointing='left'>
            {prodReleases?.length ?? 0}
          </Label>
        </Button>
        {deploymentCount > 0 &&   
          <Statistic size='mini' color='red' horizontal className='deployment-count'>
            <StatisticValue>{deploymentCount}</StatisticValue>
            <StatisticLabel>Deployments Running</StatisticLabel>
          </Statistic>
          }
      </div>
      <Table definition>
        <TableHeader>
          <TableRow>
            <TableHeaderCell/>
            <TableHeaderCell>Dev</TableHeaderCell>
            {!props.isDevToProd && <TableHeaderCell>Beta</TableHeaderCell>}
            <TableHeaderCell>Production</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.releases.map((release, releaseIndex) => (
            <TableRow className = 'release-row' key={`release-row-${releaseIndex}`}>
              <TableCell><a className='release-name' href={release.url}>{release.releaseName}</a></TableCell>
              <TableCell>
                <div className='release-environment-cell'>
                  <ReleaseEnvironmentPill releaseEnvironment={getReleaseEnvironment(release, Environments.Dev)}/>
                  {showConnector(release, Environments.Dev, props.isDevToProd ? Environments.Prod : Environments.Beta) && 
                  <ReleaseConnector
                      deploying={isReleaseDeploying(release, Environments.Dev, props.isDevToProd ? Environments.Prod : Environments.Beta)}/>}
                </div>
              </TableCell>
              {!props.isDevToProd && 
              <TableCell>
                <div className='release-environment-cell'>
                  <ReleaseEnvironmentPill releaseEnvironment={getReleaseEnvironment(release, Environments.Beta)}/>
                  {showConnector(release, Environments.Beta, Environments.Prod) && 
                    <ReleaseConnector 
                      deploying={isReleaseDeploying(release, Environments.Beta, Environments.Prod)}/>}
                </div>
              </TableCell>
              }
              <TableCell>
                <div className='release-environment-cell'>
                  <ReleaseEnvironmentPill releaseEnvironment={getReleaseEnvironment(release, Environments.Prod)}/>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <AzureDevOpsReleaseModal 
        open={deployModalState.open}
        releases={deployModalState.releases}
        fromEnvironment={deployModalState.fromEnvironment}
        toEnvironemnt={deployModalState.toEnvironment}
        onReleaseStarted={onDeployModalReleaseStarted} 
        onCancelClick={onDeployModalCancelClick}/>
    </div>
  );
}
