import './FeatureFlagExperimentStats.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { FeatureFlagExperimentAnalytics } from './types';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { useApi } from '../../hooks/useApi';
import { FeatureFlagExperimentStatistic } from './FeatureFlagExperimentStatistic';
import Loader from '../Loader';
import { DynamicTable } from '../DynamicTable/DynamicTable';
import { FeatureFlagExperimentStatisticFilters } from './FeatureFlagExperimentStatisticFilters';

export function FeatureFlagExperimentStats() {
  const { featureFlagId, featureFlagExperimentId } = useParams();
  const navigate = useNavigate();
  const [excludeManualinvoice, setExcludeManualInvoice] = React.useState(false);
  const query = excludeManualinvoice ? `?excludeManualInvoice=${excludeManualinvoice}` : '';
  const [featureFlagAnalytics, _featureFlagAnalyticsLoading, featureFlagAnalyticsLoaded] 
    = useApi<FeatureFlagExperimentAnalytics[]>(`/api/featureflags/${featureFlagId}/${featureFlagExperimentId}/analytics${query}`);
  
  const controlGroupStats = featureFlagAnalytics?.find(analytics => analytics?.isControlGroup);
  const experimentGroupStats = featureFlagAnalytics?.find(analytics => analytics?.isControlGroup == false);

  const onBackClick = () => {  
    navigate("../");
  }

  const onClientExperimentRowClick = (row) => { 
    navigate(`/account/${row.clientId}`);
  }

  const onExcludeManualInvoiceChange = (value: boolean) => {
    setExcludeManualInvoice(value);
  }

  const loading = !featureFlagAnalyticsLoaded; 
  return (
    <div className="feature-flag-experiment-stats">
      <Loader isLoading={loading} />
      <div className="header">
        <Button className="back-button" onClick={onBackClick} circular icon='arrow alternate circle left outline'></Button>
        <h2>Experiment Statistics</h2>
      </div>
      <div className='feature-flag-experiment-filters'> 
        <FeatureFlagExperimentStatisticFilters excludeManualinvoice={excludeManualinvoice} onExcludeManualInvoiceChange={onExcludeManualInvoiceChange} />
      </div>
      <div className="feature-flag-experiment-tiles">
        <FeatureFlagExperimentStatistic title='Control Group' analytics={controlGroupStats} />
        <FeatureFlagExperimentStatistic title='Experiment Group' analytics={experimentGroupStats} />
      </div>
      
      <div className="feature-experiment-clients">
       <DynamicTable 
          header='Clients'
          subHeader='*View is limited to latest 5000 clients. Click on a row to view client details.'
          url={`/api/featureflags/${featureFlagId}/${featureFlagExperimentId}/clients${query}`}
          hyperLinkColumns={[2, 3]}
          onColumnClick={onClientExperimentRowClick} />
      </div>
    </div>
  );
}