import { FeatureFlagDataType, IFeatureFlagValue } from "./types";


export const getDefaultValueFromFeatureObject = (obj: IFeatureFlagValue, dataType: FeatureFlagDataType) => { 
  return dataType === FeatureFlagDataType.BoolValue ? obj.boolValue
  : dataType === FeatureFlagDataType.NumberValue ? obj.numberValue
  : dataType === FeatureFlagDataType.DecimalValue ? obj.decimalValue
  : dataType === FeatureFlagDataType.JsonValue ? obj.jsonValue 
  : dataType === FeatureFlagDataType.StringValue ? obj.stringValue
  : null;
}