import React from 'react';
import './FeatureFlagExperimentStatisticFilters.scss';
import { Accordion, Checkbox } from 'semantic-ui-react';

interface FeatureFlagExperimentStatisticFiltersProps { 
  excludeManualinvoice: boolean;
  onExcludeManualInvoiceChange: (value: boolean) => void;
}

export function FeatureFlagExperimentStatisticFilters(props: FeatureFlagExperimentStatisticFiltersProps) {

  const [toggled, setToggled] = React.useState(false);

  const onAccordianClicked = () => { 
    setToggled(!toggled);
  }
  return (
    <Accordion fluid styled className='feature-flag-experiment-statistic-filters'>
      <Accordion.Title active={toggled} index={0} onClick={onAccordianClicked}>Filters</Accordion.Title>
      <Accordion.Content active={toggled}>
        <div className='ffesf-field-group'>
          <div className='ffesf-field-value'>
            <Checkbox label='Exclude Manual Invoice' checked={props.excludeManualinvoice} onChange={(_e, data) => props?.onExcludeManualInvoiceChange?.(data.checked)} />
          </div>
        </div>
      </Accordion.Content>
    </Accordion>
  );
}